<template>
  <div class="manage-project-booking__pending">
    <div v-if="!_.isEmpty(API)">
      <pending-booking-table
        :canManage="!isAssigned"
        :getBookingsAPI="API.getBookings"
        :approveBookingAPI="API.approveBooking"
        :rejectBookingAPI="API.rejectBooking"
        :cancelBookingAPI="API.cancelBooking"
        :detailRouteName="getDetailRouteName()"
      ></pending-booking-table>
    </div>
  </div>
</template>

<script>
import PendingBookingTable from "@/components/GlobalComponents/Shared/ProjectSales/Booking/PendingBookingTable";
import { projectBooking as bookingAPI } from "@/api";

export default {
  components: { PendingBookingTable },
  mixins: [],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      API: {}
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initAPI();
  },
  methods: {
    initAPI() {
      this.API = bookingAPI.getProjectBookingAPI(this.isAssigned);
    },
    getDetailRouteName() {
      return this.isAssigned
        ? "ManageAssignedProjectBookingDetail"
        : "ManageProjectBookingDetail";
    }
  }
};
</script>

<style lang="scss"></style>
